import { useTimeDelta } from "@daybridge/server-time"
import { useMeQuery } from "@daybridge/client-api"
import { useAuth } from "@daybridge/auth"
import { useEffect } from "react"
import { setUser } from "@sentry/nextjs"
import { useItemSchema } from "../features/items/hooks/useItemSchema"
import { useInfiniteCalendarsQuery } from "../features/calendars/hooks/useInfiniteCalendarsQuery"

/**
 * `useBootstrap` initialises the app. Note that by default queries to the server
 * are configured to use error boundaries in the event that they fail.
 *
 * @returns `true` if the application has finished bootstrapping, `false` otherwise.
 */
export const useBootstrap = (skipAuth?: boolean): boolean => {
  // In order to run these queries, the user needs to be logged in.
  // Usually it's not necessary to explicitly check the user is logged in,
  // but boostrapping happens outside of the authentication coordinator.
  const { user, loading: userLoading } = useAuth()

  // We'll wait to touch base with the server and obtain a "time delta"
  // which is the difference between the client's clock and the server's
  // clock. This is used to calculate the current time on the client.
  const timeDelta = useTimeDelta(!user)

  // Fetch item schema
  const { isSuccess: schemaFetched, isError: schemaError } = useItemSchema({
    enabled: !!user,
  })

  // Fetch user account info
  const { isSuccess: accountFetched, isError: accountError } = useMeQuery(
    undefined,
    {
      enabled: !!user,
    },
  )

  // Fetch calendars
  const { isSuccess: calendarsFetched, isError: calendarsError } =
    useInfiniteCalendarsQuery(
      {
        args: {
          filterBy: { deleted: false },
        },
      },
      {
        enabled: !!user,
      },
    )

  // Set the User object for Sentry when it becomes available
  useEffect(() => {
    if (!user) {
      return
    }

    setUser({
      id: user.uid,
      provider: user.providerId,
    })
  }, [user])

  // If this page doesn't require login, skip waiting for bootstrapping,
  // but only if the user isn't loading right now.
  if (skipAuth && !user && !userLoading) {
    return true
  }

  // Completion condition
  const complete =
    (calendarsFetched || calendarsError) &&
    (accountFetched || accountError) &&
    (schemaFetched || schemaError) &&
    timeDelta !== undefined

  return complete
}
