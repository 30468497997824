import { UseQueryOptions, UseQueryResult } from "@tanstack/react-query"
import {
  Activity,
  ActivityId,
  Category,
  CategoryId,
  ItemTypeId,
  ItemType,
  ItemSchemaQuery,
  useItemSchemaQuery,
  IconSet,
  IconSetId,
} from "@daybridge/client-api"
import { GraphQLError } from "@daybridge/graphql"

export interface ItemSchema {
  activities: { [activity in ActivityId]: Activity }
  categories: { [category in CategoryId]: Category }
  iconSets: { [iconSet in IconSetId]: IconSet }
  itemTypes: { [itemType in ItemTypeId]: ItemType }
}

const select = (data: ItemSchemaQuery): ItemSchema => {
  const activitiesList = data.activities?.edges.map((edge) => edge.node) || []
  const categoriesList = data.categories?.edges.map((edge) => edge.node) || []
  const iconSetsList = data.iconSets?.edges.map((edge) => edge.node) || []
  const itemTypesList = data.itemTypes?.edges.map((edge) => edge.node) || []

  const activities: { [activity in ActivityId]?: Activity } = {}
  const categories: { [category in CategoryId]?: Category } = {}
  const iconSets: { [iconSet in IconSetId]?: IconSet } = {}
  const itemTypes: { [itemType in ItemTypeId]?: ItemType } = {}

  activitiesList.forEach((activity) => {
    activities[activity.id] = activity
  })

  categoriesList.forEach((category) => {
    categories[category.id] = category
  })

  iconSetsList.forEach((iconSet) => {
    iconSets[iconSet.id] = iconSet
  })

  itemTypesList.forEach((itemType) => {
    itemTypes[itemType.id] = itemType
  })

  return {
    activities: activities as { [activity in ActivityId]: Activity },
    categories: categories as { [category in CategoryId]: Category },
    iconSets: iconSets as { [iconSet in IconSetId]: IconSet },
    itemTypes: itemTypes as { [itemType in ItemTypeId]: ItemType },
  }
}

export const useItemSchema = (
  options?: UseQueryOptions<ItemSchemaQuery, string | GraphQLError, ItemSchema>,
): UseQueryResult<ItemSchema> => {
  return useItemSchemaQuery<ItemSchema>(undefined, {
    select,
    ...options,
  })
}
