import "../styles/styles.css"
// eslint-disable-next-line import/no-unassigned-import
import "focus-visible"
import { AppProps } from "next/dist/shared/lib/router/router"
import React, { useEffect, useRef, useState } from "react"
import {
  GraphQLClientProvider,
  GraphQLError,
  retryServerErrors,
} from "@daybridge/graphql"
import {
  AuthCoordinator,
  AuthProvider,
  init as initAuth,
} from "@daybridge/auth"
import { ThemeProvider } from "@daybridge/theme"
import { Toaster } from "@daybridge/toast"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { RecoilRoot } from "recoil"
import { ErrorBoundary } from "@sentry/nextjs"
import { TooltipProvider } from "@daybridge/components-core"
import { useRouter } from "next/router"
import { AnalyticsProvider } from "@daybridge/analytics"
import { persistQueryClient } from "@tanstack/react-query-persist-client"
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister"
import { Head } from "../components/Head"
import { Bootstrap } from "../components/Bootstrap"
import { UserThemePreferenceProvider } from "../components/UserThemePreferenceProvider"
import { ErrorScreen } from "../components/ErrorScreen"
import env from "../env.config"
import SplashIfMobile from "../components/SplashIfMobile"
import { LoadingScreen } from "../components/LoadingScreen"
import { PageWithMetadata } from "../types/PageWithMetadata"
import { BeamsProvider } from "../features/notifications/components/BeamsClientProvider"

// Configure Firebase Authentication
initAuth({
  apiKey: env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  appId: env.NEXT_PUBLIC_FIREBASE_APP_ID,
})

/**
 * `App` is the top-level component which wraps all pages.
 * The App component is persisted across page transitions.
 */
const App: React.FC<AppProps> = (props: AppProps) => {
  const Component = props.Component as PageWithMetadata
  const getLayout = Component.getLayout ?? ((page) => page)
  const router = useRouter()
  // Initialise React Query Client. We originally put this outside of the
  // component, but that was causing a few problems in development mode with hot
  // reloading. Since the Query Client instance will never change and should not
  // trigger any new renders, we should put it inside a ref.
  // See: https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
  const CACHE_MAX_AGE_MS = 1000 * 60 * 60 * 24 * 7 // 7 days

  const [signupError, setSignupError] = useState(false)
  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          cacheTime: CACHE_MAX_AGE_MS,
          // If an error occurs during fetch, propagate the error
          // to the nearest error boundary.
          // TODO: Consider moving to Suspense
          useErrorBoundary: (e: unknown) => {
            if (
              (e as GraphQLError).response &&
              (e as GraphQLError).response.errors[0].extensions.message ===
                "You need to have completed signup before you can continue."
            ) {
              return false
            }
            return true
          },

          // By default, only retry server errors up to 3 times
          retry: retryServerErrors,
          onError: (e: unknown) => {
            if (
              (e as GraphQLError).response &&
              (e as GraphQLError).response.errors[0].extensions.message ===
                "You need to have completed signup before you can continue."
            ) {
              // Set state instead of handling the error, because we don't have
              // access to an up-to-date router here.
              setSignupError(true)
            }
          },
        },
      },
    }),
  ).current

  useEffect(() => {
    if (signupError && !router.pathname.startsWith("/signup")) {
      setSignupError(false)
      // Don't redirect in the onboarding flow. Even though we should not
      // receive any "user_not_signed_up" errors at that point, there might be
      // situations in which the query response comes in just after signing up
      // when the user is already in onboarding, which would result in a
      // redirect from the onboarding flow back to the sign-up page.
      if (!router.pathname.startsWith("/onboarding")) {
        void router.replace("/signup")
      }
    }
  }, [router, signupError])

  // Configure query client persistence
  useEffect(() => {
    if (!queryClient) {
      return
    }

    const localStoragePersister = createSyncStoragePersister({
      storage: window.localStorage,
    })

    void persistQueryClient({
      queryClient,
      persister: localStoragePersister,
      buster:
        env.NODE_ENV === "production"
          ? // Clear the cache every time a new build is released
            env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
          : // Clear the cache every time the app is restarted
            // (NEXT_PUBLIC_DEV_SESSION_KEY) is set when the dev server boots
            // in the package.json file.
            env.NEXT_PUBLIC_DEV_SESSION_KEY,
      maxAge: CACHE_MAX_AGE_MS,
    })
  }, [queryClient, CACHE_MAX_AGE_MS])

  // Keep track of whether the app has finished bootstrapping. This
  // will be used to determine whether the loading screen should be
  // shown.
  const [appHasFinishedBootstrapping, setAppHasFinishedBootstrapping] =
    useState(!!Component.skipBootstrap)

  return (
    // Outer error boundary doesn't have access to auth state
    <ErrorBoundary
      fallback={({ error, componentStack }) => (
        <ErrorScreen error={error} componentStack={componentStack} />
      )}
    >
      <AuthProvider>
        <ErrorBoundary
          fallback={({ error, componentStack }) => (
            <ErrorScreen error={error} componentStack={componentStack} />
          )}
        >
          <RecoilRoot>
            <QueryClientProvider contextSharing client={queryClient}>
              <GraphQLClientProvider
                serverUrl={
                  env.NEXT_PUBLIC_GRAPHQL_BASE_URI ||
                  "https://api.daybridge.com/graphql"
                }
                options={{
                  headers: {
                    "X-Daybridge-Analytics-Event": "true",
                  },
                }}
              >
                <AnalyticsProvider>
                  <GraphQLClientProvider
                    serverUrl={
                      env.NEXT_PUBLIC_GRAPHQL_BASE_URI ||
                      "https://api.daybridge.com/graphql"
                    }
                  >
                    <Bootstrap
                      skipAuth={Component.skipAuth || false}
                      onComplete={() => setAppHasFinishedBootstrapping(true)}
                    />
                    <UserThemePreferenceProvider>
                      <Head />
                      <ThemeProvider
                        id="theme-root"
                        className="w-full h-full relative z-0"
                      >
                        <TooltipProvider>
                          <SplashIfMobile
                            skipMobileSplash={!!Component.skipMobileSplash}
                          >
                            <AuthCoordinator
                              skipAuth={Component.skipAuth}
                              skipBootstrap={Component.skipBootstrap}
                              signInPath="/login"
                              loadingScreenComponent={LoadingScreen}
                              loading={!appHasFinishedBootstrapping}
                            >
                              <BeamsProvider>
                                {getLayout(<Component {...props.pageProps} />)}
                              </BeamsProvider>
                            </AuthCoordinator>
                          </SplashIfMobile>
                          <ReactQueryDevtools position="bottom-right" />
                        </TooltipProvider>
                      </ThemeProvider>
                      {/* Toasts are separated out here to make sure they always appear on top of other content */}
                      <ThemeProvider>
                        <Toaster />
                      </ThemeProvider>
                    </UserThemePreferenceProvider>
                  </GraphQLClientProvider>
                </AnalyticsProvider>
              </GraphQLClientProvider>
            </QueryClientProvider>
          </RecoilRoot>
        </ErrorBoundary>
      </AuthProvider>
    </ErrorBoundary>
  )
}

export default App
