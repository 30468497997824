import { useAuth } from "@daybridge/auth"
import { Theme, useMeQuery } from "@daybridge/client-api"
import { ThemePreferenceProvider } from "@daybridge/theme"
import React from "react"

interface UserThemePreferenceProviderProps {
  children?: React.ReactNode | React.ReactNode[]
}

/**
 * `userThemePreferenceProvider` pulls the user's theme preference from
 * their account settings and makes it available to the whole app.
 */
export const UserThemePreferenceProvider: React.FC<UserThemePreferenceProviderProps> =
  React.memo((props: UserThemePreferenceProviderProps) => {
    const { user } = useAuth()
    const { data: account } = useMeQuery(undefined, {
      // Don't run if not logged in
      enabled: !!user,

      // User may not have an account yet, so we need to handle that
      useErrorBoundary: false,
    })

    const theme = account?.me?.account.preferences?.theme ?? Theme.System

    return (
      <ThemePreferenceProvider value={theme}>
        {props.children}
      </ThemePreferenceProvider>
    )
  })
UserThemePreferenceProvider.displayName = "UserThemePreferenceProvider"
