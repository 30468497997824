import React, { useEffect } from "react"
import Image from "next/image"
import { Button, ButtonSize, ButtonVariant } from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { IconId } from "@daybridge/client-api"
import { useMobileOS } from "../features/settings/state/mobileOS"

interface SplashIfMobileProps {
  children: React.ReactElement
  skipMobileSplash: boolean
}

const SplashIfMobile: React.FC<SplashIfMobileProps> = React.memo(
  (props: SplashIfMobileProps) => {
    const [mobileOS, setMobileOS] = useMobileOS()

    // Detect if the user is on an iOS or Android device based on the user agent.
    // This only runs once, on the first render.
    useEffect(() => {
      const userAgent: string = window.navigator.userAgent
      if (/android/i.test(userAgent)) {
        setMobileOS("Android")
      } else if (/iPhone|iPod/.test(userAgent)) {
        // iPhones and iPods always use this capitalization in their user agents, so we won't need
        // to check for lowercase. iPad user agents also contain 'iPhone' - but we don't want them
        // to see the splash screen, so we exclude them here.
        if (!userAgent.includes("iPad")) {
          setMobileOS("iOS")
        }
      }
    }, [setMobileOS])

    return mobileOS && !props.skipMobileSplash ? (
      <div
        className={`
          w-full h-full bg-background px-10
          flex flex-col items-center justify-center
        `}
      >
        <div className="relative">
          <div
            className={`
              absolute z-10 inset-0 top-12 
              bg-gradient-to-t from-background via-background
            `}
          />
          <div className="dark:hidden">
            <Image
              alt={`${mobileOS.toLowerCase()} icon`}
              src={`/assets/onboarding/mobile/${mobileOS.toLowerCase()}-icon-light-active.svg`}
              unoptimized
              width={116}
              height={100}
              role="presentation"
            />
          </div>
          <div className="hidden dark:block">
            <Image
              alt={`${mobileOS.toLowerCase()} icon`}
              src={`/assets/onboarding/mobile/${mobileOS.toLowerCase()}-icon-dark-active.svg`}
              unoptimized
              width={116}
              height={100}
              role="presentation"
            />
          </div>
        </div>
        <div
          className={`
            relative z-10 
            -mt-2
            text-lg text-center font-semibold text-high-contrast
          `}
        >
          Download the {mobileOS} app
        </div>
        <div
          className={`
            relative z-10 
            mt-2 mb-6 max-w-xs
            text-center text-base text-low-contrast
          `}
        >
          Please download the Daybridge app to continue.
        </div>
        {mobileOS === "iOS" && (
          <a
            href="https://app.daybridge.com/download/ios"
            className={`
            transition duration-300 ease-in-out
            focus:outline-none focus:ring-2 ring-offset-2 ring-focus-ring ring-offset-surface
            rounded
          `}
          >
            <Button
              variant={ButtonVariant.Primary}
              size={ButtonSize.Medium}
              className="bg-surface"
            >
              <I type={IconId.Apple} className="w-[14px] h-[14px] mr-2" />
              App Store
            </Button>
          </a>
        )}
        {mobileOS === "Android" && (
          <a
            href="https://app.daybridge.com/download/android"
            className={`
            transition duration-300 ease-in-out
            focus:outline-none focus:ring-2 ring-offset-2 ring-focus-ring ring-offset-surface
            rounded
          `}
          >
            <Button
              variant={ButtonVariant.Primary}
              size={ButtonSize.Medium}
              className="bg-surface"
            >
              <I type={IconId.Google} className="w-[14px] h-[14px] mr-2" />
              Play Store
            </Button>
          </a>
        )}
      </div>
    ) : (
      props.children
    )
  },
)

SplashIfMobile.displayName = "SplashIfMobile"
export default SplashIfMobile
