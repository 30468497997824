import { atom, useRecoilState } from "recoil"

export type MobileOS = "iOS" | "Android"

// This atom is used to store the mobile OS of the user, or undefined if the
// user is using Daybridge on desktop. It is set by the `SplashIfMobile`
// component on mount, and is useful to render different flows for mobile users
// (i.e. when using the same component with responsive CSS is not enough).
export const mobileOSAtom = atom<MobileOS | undefined>({
  key: "mobile_os",
  default: undefined,
})
export const useMobileOS = () => useRecoilState(mobileOSAtom)
