import { useAuth } from "@daybridge/auth"
import { IconId } from "@daybridge/client-api"
import { Button, ButtonSize, ButtonVariant } from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import React from "react"

interface ErrorScreenProps {
  error: unknown
  componentStack: string | null
}

/**
 * ErrorScreen is a full-screen error used when an unrecoverable problem occurs
 * in the app.
 */

export const ErrorScreen: React.FC<ErrorScreenProps> = React.memo(
  (props: ErrorScreenProps) => {
    const { user, signOut } = useAuth()

    const copyDetails = async () => {
      if (typeof props.error === "object") {
        const { name, message, cause, ...rest } = props.error as Error
        await navigator.clipboard.writeText(
          JSON.stringify({
            name,
            message,
            cause,
            componentStack: props.componentStack,
            ...rest,
          }),
        )
      } else {
        await navigator.clipboard.writeText(props.error as string)
      }
    }

    return (
      <div
        className="
          w-full h-full
          flex items-center justify-center
          bg-gradient-to-b from-surface to-behind-surface
        "
      >
        <div className="w-full flex flex-col items-center z-10">
          <div
            className="
              w-16 h-16 p-5 -mb-8 z-10 
              bg-elevated text-error
              rounded-full shadow-sm
              border border-tint
            "
          >
            <I type={IconId.Bug} />
          </div>
          <div
            className="
              w-full sm:max-w-[24rem] p-8 pt-14 mb-16
              sm:bg-surface
              sm:rounded-xl
              sm:ring-1 ring-inset ring-tint
              sm:shadow-diffuse
            "
          >
            <h1
              className="
                w-full mb-2 flex items-center justify-center
                text-high-contrast text-lg
                font-semibold
              "
            >
              Sorry, there&apos;s been a problem
            </h1>
            <p
              className="
                w-full 
                text-center
                text-low-contrast
                text-base
                mb-4
              "
            >
              This shouldn&apos;t be happening. We&apos;ve reported it to our
              engineers so they can look into it.
            </p>
            <Button
              variant={ButtonVariant.Primary}
              size={ButtonSize.Medium}
              block
              onPress={() => window.location.reload()}
              className="mb-8"
            >
              <I type={IconId.Repeat} className="w-3 h-3 mr-2 inline" /> Reload
              Daybridge
            </Button>
            <p
              className="
                w-full
                text-center
                text-low-contrast
                text-base
                mb-4
              "
            >
              <strong className="font-semibold">
                Does this keep happening?
              </strong>{" "}
              We might be having some technical problems. Have a look at our
              status feed for updates.
            </p>
            <Button
              variant={ButtonVariant.Outline}
              size={ButtonSize.Medium}
              block
              onPress={() => window.open("https://twitter.com/daybridgestatus")}
            >
              <I type={IconId.Daybridge} className="w-4 h-4 mr-2 inline" />{" "}
              Daybridge Status
            </Button>
          </div>
          <div className="block w-full text-center space-x-4">
            <Button
              variant={ButtonVariant.Outline}
              size={ButtonSize.Small}
              onPress={() => void copyDetails()}
            >
              <I type={IconId.Copy} className="w-4 h-4 mr-2 inline" />{" "}
              <span>Copy error details</span>
            </Button>

            {user && (
              <Button
                variant={ButtonVariant.Outline}
                size={ButtonSize.Small}
                onPress={() => {
                  void signOut().then(() => {
                    window.location.reload()
                  })
                }}
              >
                <I type={IconId.SignOut} className="w-4 h-4 mr-2 inline" />{" "}
                <span>Sign out</span>
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  },
)
ErrorScreen.displayName = "ErrorScreen"
