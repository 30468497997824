import {
  CalendarsDocument,
  CalendarsArgs,
  CalendarsQuery,
  CalendarsQueryVariables,
  useCalendarsQuery,
} from "@daybridge/client-api"
import { GraphQLError, useFetcher } from "@daybridge/graphql"
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query"

export const useInfiniteCalendarsQuery = <
  TData = CalendarsQuery,
  TError = string | GraphQLError,
>(
  variables: CalendarsQueryVariables,
  options?: UseInfiniteQueryOptions<CalendarsQuery, TError, TData>,
) => {
  const fetcher = useFetcher<CalendarsQuery, CalendarsQueryVariables>(
    CalendarsDocument,
  )

  return useInfiniteQuery<CalendarsQuery, TError, TData>(
    useCalendarsQuery.getKey(variables),
    (metaData: { pageParam?: CalendarsArgs }) =>
      fetcher.bind(null, {
        args: { ...variables.args, ...(metaData.pageParam ?? {}) },
      })(),
    {
      getNextPageParam: (
        lastPage: CalendarsQuery,
      ): CalendarsArgs | undefined => {
        return lastPage.calendars?.pageInfo?.hasNextPage
          ? {
              first: lastPage.calendars.edges.length,
              after: lastPage.calendars.pageInfo.endCursor,
            }
          : undefined
      },
      getPreviousPageParam: (
        firstPage: CalendarsQuery,
      ): CalendarsArgs | undefined => {
        return firstPage.calendars?.pageInfo?.hasPreviousPage
          ? {
              last: firstPage.calendars.edges.length,
              before: firstPage.calendars.pageInfo.startCursor,
            }
          : undefined
      },
      ...options,
    },
  )
}
