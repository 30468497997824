import React, { useEffect } from "react"
import { useBootstrap } from "../hooks/useBootstrap"

interface BootstrapProps {
  skipAuth: boolean

  // `onComplete` is a callback to be invoked when the bootstrap process has
  // finished.
  onComplete: () => void
}

/**
 * Bootstrap is a wrapper component that initialises the app and then reports
 * completion to the parent component when done.
 */
export const Bootstrap: React.FC<BootstrapProps> = React.memo((props) => {
  const appHasFinishedBootsrapping = useBootstrap(props.skipAuth)

  useEffect(() => {
    if (!appHasFinishedBootsrapping) return
    props.onComplete()
  }, [props, appHasFinishedBootsrapping])

  return <div />
})
Bootstrap.displayName = "Bootstrap"
