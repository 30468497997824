import { AnimatePresence, motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import {
  FadeAndScaleAnimation,
  Logo,
  Spinner,
} from "@daybridge/components-core"

interface LoadingScreenProps {
  show: boolean
}

export const LoadingScreen: React.FC<LoadingScreenProps> = () => {
  const [takingTooLong, setTakingTooLong] = useState(false)
  useEffect(() => {
    const t = setTimeout(() => setTakingTooLong(true), 3000)
    return () => clearTimeout(t)
  }, [])

  return (
    <div
      className="
        w-full h-full 
        flex items-center justify-center 
        text-high-contrast
        bg-gradient-to-b from-surface to-background
      "
    >
      <motion.div
        initial={{ scale: 1.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.75 }}
        className="z-10 w-24 h-24 text-primary-body dark:text-high-contrast"
      >
        <Logo />
      </motion.div>

      <AnimatePresence>
        {takingTooLong ? (
          <div className="absolute bottom-24 z-10">
            <FadeAndScaleAnimation>
              <div
                className="
                  py-3 px-6 
                  flex flex-row items-center justify-center 
                  space-x-3 
                  bg-surface 
                  text-base text-high-contrast 
                  rounded-lg 
                  shadow-diffuse 
                  border border-tint
                "
                aria-live="assertive"
              >
                <div className="w-4 h-4 text-primary">
                  <Spinner />
                </div>
                <div>Daybridge is taking longer to load than usual</div>
              </div>
            </FadeAndScaleAnimation>
          </div>
        ) : null}
      </AnimatePresence>
    </div>
  )
}
