import React from "react"
// eslint-disable-next-line import/no-named-default
import { default as NextHead } from "next/head"
import { Theme } from "@daybridge/client-api"
import { colors } from "@daybridge/colors"
import { useAuth } from "@daybridge/auth"
import usePreference from "../features/settings/hooks/preferences/usePreference"

// This component wraps all tags that should go in the `head`. You can adjust
// tags later by including them in a `next/head` component.
export const Head: React.FC = React.memo(() => {
  // Check the theme of the user so we can set the browser color to the same
  // value (if the browser supports it, like Safari).
  const { user } = useAuth()
  const [theme] = usePreference("theme", {
    useErrorBoundary: false,
    enabled: !!user,
  })
  const isSystem = !theme || theme === Theme.System
  return (
    <NextHead>
      <title>Daybridge</title>
      {isSystem && (
        <>
          <meta
            key="theme-light"
            name="theme-color"
            media="(prefers-color-scheme: light)"
            content={colors.Gray50}
          />
          <meta
            key="theme-dark"
            name="theme-color"
            media="(prefers-color-scheme: dark)"
            content={colors.Gray900}
          />
        </>
      )}
      {!isSystem && (
        <meta
          key="theme-system"
          name="theme-color"
          content={theme === Theme.Dark ? colors.Gray900 : colors.Gray50}
        />
      )}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <link
        key="favicon"
        rel="shortcut icon"
        type="image/x-icon"
        href="/favicon.ico"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
    </NextHead>
  )
})

Head.displayName = "Head"
