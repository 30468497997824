// This is a central registry of all of the environment variables the app uses.
// -----------------------------------------------------------------------------
// WARNING
// -----------------------------------------------------------------------------
// When adding new environment variables, edit the central `package.json` file so
// that they are considered during the build process. Many of these environment
// variables are baked into the app at build-time, so if they change, the cache
// needs to be invalidated. Failure to do this could result in preview environment
// variable values being shipped to production!

module.exports = {
  // GraphQL endpoint
  NEXT_PUBLIC_GRAPHQL_BASE_URI: process.env.NEXT_PUBLIC_GRAPHQL_BASE_URI,

  // Sentry config
  SENTRY_DSN: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,

  // Pusher config
  NEXT_PUBLIC_PUSHER_BEAMS_INSTANCE_ID:
    process.env.NEXT_PUBLIC_PUSHER_BEAMS_INSTANCE_ID,

  // Vercel variables
  NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  NEXT_PUBLIC_VERCEL_GIT_REPO_OWNER:
    process.env.NEXT_PUBLIC_VERCEL_GIT_REPO_OWNER,
  NEXT_PUBLIC_VERCEL_GIT_REPO_SLUG:
    process.env.NEXT_PUBLIC_VERCEL_GIT_REPO_SLUG,
  NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,

  // Firebase
  NEXT_PUBLIC_FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  NEXT_PUBLIC_FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN:
    process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,

  // Node
  NODE_ENV: process.env.NODE_ENV,

  // Local
  NEXT_PUBLIC_DEV_SESSION_KEY: process.env.NEXT_PUBLIC_DEV_SESSION_KEY,

  // Mapbox
  NEXT_PUBLIC_MAPBOX_PUBLIC_ACCESS_TOKEN:
    process.env.NEXT_PUBLIC_MAPBOX_PUBLIC_ACCESS_TOKEN,
}
