import React, { createContext, useEffect, useMemo } from "react"
import { Client as BeamsClient } from "@pusher/push-notifications-web"
import { useAuth } from "@daybridge/auth"
import env from "../../../env.config"

interface BeamsClientProviderProps {
  children?: React.ReactNode
}

export const beamsContext = createContext<BeamsClient | undefined>(undefined)

export const BeamsProvider: React.FC<BeamsClientProviderProps> = (props) => {
  const client = useMemo(() => {
    try {
      return typeof window !== "undefined"
        ? new BeamsClient({
            instanceId: env.NEXT_PUBLIC_PUSHER_BEAMS_INSTANCE_ID || "",
          })
        : undefined
    } catch (err) {
      // Fail silently
      console.error("🔔 Failed to initialise Beams client", err)
      return undefined
    }
  }, [])

  const { user } = useAuth()

  // If the user becomes logged out, stop the client.
  useEffect(() => {
    if (client) {
      void client.getUserId().then((userId) => {
        if (userId && !user) {
          client
            .stop()
            .then(() => {
              console.log("🔔 Notifications stopped")
            })
            .catch((err) => {
              console.error("🔔 Notifications failed to stop", err)
            })
        }
      })
    }
  }, [user, client])

  // Make it available to all children via this provider.
  return (
    <beamsContext.Provider value={client}>
      {props.children}
    </beamsContext.Provider>
  )
}
