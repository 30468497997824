import {
  PatchPreferencesMutationVariables,
  useMeQuery,
  usePatchPreferencesMutation,
  MeQuery,
} from "@daybridge/client-api"
import { produce } from "immer"
import { useCallback, useMemo } from "react"
import { useQueryClient } from "@tanstack/react-query"
import {
  AccessiblePreferenceKeys,
  PreferenceMutationOptions,
  PreferenceQueryOptions,
  PreferenceValueAndSetter,
  TypeOfPreference,
} from "../../types/preferences"

/**
 * `usePreference` is a React Hook that allows you to access the value of one of
 * the user's preferences. It also implements a setter with optimistic UI so the
 * setting can take effect immediately, while updating in the background. Note
 * that this hook doesn't handle errors or loading states, but the setter does
 * return a Promise that can be used to handle those (for example, with a
 * toast).
 *
 * NOTE: if TypeScript ever complains about P not being able to index
 * `TypeOfPreference`, there are new preferences available that we haven't yet
 * added to the preference query.
 */
function usePreference<P extends AccessiblePreferenceKeys>(
  name: P,
  queryOptions?: PreferenceQueryOptions<P>,
): PreferenceValueAndSetter<P> {
  // The `select` function extracts a specific preference from the
  // preferences query result. It is important to define this using
  // a useCallback hook so that it doesn't get re-created every time
  // the hook is invoked, causing unnecessary re-renders.
  // See https://tkdodo.eu/blog/react-query-data-transformations#3-using-the-select-option
  const select = useCallback(
    (values: MeQuery): TypeOfPreference[P] =>
      values.me?.account.preferences?.[name] as TypeOfPreference[P],
    [name],
  )

  // We need access to a query client to invalidate the account and preferences
  // queries when the preference is set.
  const queryClient = useQueryClient()

  // Fetch the preference from the `MeQuery`. Since this query is run during
  // bootstrapping it's more likely that the data will be available straight away.
  const { data: preference } = useMeQuery(undefined, {
    ...queryOptions,
    // Select appears after `queryOptions` so that the caller can't overwrite it
    select,
    refetchOnMount: false,
    keepPreviousData: true,
  })

  const onMutate = useCallback(
    async (variables: PatchPreferencesMutationVariables) => {
      const queryKey = useMeQuery.getKey()

      // Cancel any in-flight queries
      await queryClient.cancelQueries(queryKey)

      // Fetch previous state
      const previousState = queryClient.getQueryData<MeQuery>(queryKey)

      // Produce new state with updated theme
      const newState = produce(previousState, (draft) => {
        if (!draft?.me?.account.preferences) {
          // Account unavailable for some reason - can't optimistically update
          return
        }

        if (variables.input[name] === undefined) {
          return
        }

        if (variables.input[name] === null) {
          delete draft.me.account.preferences[name]
        }

        draft.me.account.preferences[name] = variables.input[
          name
        ] as TypeOfPreference[P]
        return draft
      })

      queryClient.setQueryData(queryKey, newState)
    },
    [queryClient, name],
  )

  const { mutateAsync } = usePatchPreferencesMutation({
    // `onMutate` implements optimistic updating for the preference value.
    onMutate,
  })

  const mutate = useCallback(
    async (
      value: TypeOfPreference[P],
      mutationOptions?: PreferenceMutationOptions,
    ): Promise<TypeOfPreference[P]> => {
      await mutateAsync(
        {
          input: {
            [name]: value,
          },
        },
        mutationOptions,
      )
      return value
    },
    [name, mutateAsync],
  )

  return useMemo(() => [preference, mutate], [preference, mutate])
}

export default usePreference
